import React from 'react'

function Letters() {
  return (
    <div>
      <section>
        <h1>Content to be available soon</h1>
      </section>
    </div>
  )
}

export default Letters
